<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			:class="{
				'mt-3': !['xs'].includes(windowWidth),
				'mt-2': ['xs'].includes(windowWidth),
				'mb-5': !isAgencySite,
				'mb-4': isAgencySite,
			}"
			class="row">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								:key="`1-${currentStep()}`"
								:collapse="isCollapsed(0) || (collapseAll && !isCompact(0))"
								:compact="isCompact(0) || (collapseAll && !isCollapsed(0))"
								:hide-edit="!canOpenEditing(0)"
								:init-edit="isEditing(0) && !collapseAll"
								:title="`1. ${translate('personal_information')}`"
								:hide-controls="isEditing(0) && !collapseAll"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing(0)"
								@toggled="goToStep(0); collapseAll = false">
								<personal-information-overview v-if="!isEditing(0) && !isCollapsed(0)" />
								<personal-information-edit
									v-if="isEditing(0)"
									:predefined-information="predefinedInformation.personalInformation"
									:force-create-account="predefinedInformation.forceCreateAccount"
									@opened="trackEvent($gtm, 'checkoutPersonalInfoEdit')"
									@cancel="collapseAll = true"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@cantUseSelectedSponsor="redirectToStore"
									@openedPhoneVerification="trackEvent($gtm, 'checkoutPhoneVerificationStart')"
									@saved="savePersonalInfo" />
							</flow-info-section>
							<flow-info-section
								:key="`2-${currentStep()}`"
								:collapse="isCollapsed(1) || (collapseAll && !isCompact(1))"
								:compact="isCompact(1) || (collapseAll && !isCollapsed(1))"
								:hide-edit="!canOpenEditing(1)"
								:init-edit="isEditing(1) && !collapseAll"
								:hide-controls="isEditing(1) && !collapseAll"
								:title="`2. ${translate('shipping_information')}`"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing(1)"
								@toggled="goToStep(1); collapseAll = false">
								<shipping-overview
									v-if="!isEditing(1) && !isCollapsed(1)"
									:shipping-method="shippingMethod"
									:show-shipping-alert="showShippingMethodAlert" />
								<shipping-edit
									v-if="isEditing(1)"
									:pickup-offices="pickupOffices"
									:predefined-information="predefinedInformation.shippingInformation"
									:show-shipping-alert="showShippingMethodAlert"
									@opened="trackEvent($gtm, 'checkoutShippingInfoEdit')"
									@cancel="collapseAll = true"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="getCartTotals" />
							</flow-info-section>
							<flow-info-section
								:key="`3-${currentStep()}`"
								:collapse="isCollapsed(2) || (collapseAll && !isCollapsed(2))"
								:compact="isCompact(2) || (collapseAll && !isCollapsed(2))"
								:hide-edit="!canOpenEditing(2)"
								:init-edit="isEditing(2) && !collapseAll"
								:title="`3. ${translate('payment_method')}`"
								:hide-controls="isEditing(2) && !collapseAll"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing(2)"
								@toggled="goToStep(2); collapseAll = false">
								<payment-overview
									v-if="!isEditing(2) && !isCollapsed(2)"
									:cart-total="cartNumericTotal" />
								<payment-edit
									v-if="isEditing(2)"
									:can-split-payment="canSplitPayment"
									:cart-total="cartNumericTotal"
									:cart-numeric-total="cartNumericTotal"
									@opened="trackEvent($gtm, 'checkoutPaymentInfoEdit')"
									@cancel="collapseAll = true"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="getCartTotals"
									@changePaymentMethodSelected="changePaymentMethodSelected" />
							</flow-info-section>
							<!-- <flow-info-section
								v-if="cartHasTickets"
								:compact="false"
								init-edit
								hide-controls
								:title="translate('event_attendees')"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="true">
								<div
									v-if="!systemLoading">
									<div
										class="col-12 mt-3">
										<p class="my-0 font-weight-bolder text-danger">
											{{ translate('ticket_not_refundable_disclaimer') }}
										</p>
									</div>
									<event-ticket
										v-for="(item, key) in tickets"
										:key="key"
										:ticket-code-name="item.code_name"
										:names="ticketsNames[item.sku]"
										:quantity="item.qty"
										@dataChanged="saveTickets(item.sku, $event)" />
								</div>
								<div
									v-else
									class="col-12 fade-in text-center d-flex justify-content-center align-items-center mt-3 mb-3">
									<div class="col-12">
										<div class="h2">
											<i class="fa fa-fw fa-spinner fa-pulse" />
											<h4 class="mt-3">
												{{ translate('loading') }}
											</h4>
										</div>
									</div>
								</div>
							</flow-info-section>
						</flow-info-group> -->
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('review_your_cart_items') }}</h5>
							</div>
						</div>
						<div
							v-if="showMessages"
							class="row mt-1">
							<div class="col">
								<possible-discounts
									v-if="!systemLoading"
									:possible-discount-messages="possibleDiscountMessages"
									:free-products-info="freeProductsInfo"
									:promotions="promotions" />
							</div>
						</div>
						<div class="row mt-1">
							<div class="col">
								<cart-items
									:loading="systemLoading"
									:cart-items="cartProducts"
									:qty-import="qty"
									hide-loading
									@update-quantity="updateItemQty"
									@remove-product="removeItem"
									@use-points="usePoints"
									@remove-points="removePoints" />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading"
							:has-data="cartProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:free-products-info="freeProductsInfo"
					:cart-loading="systemLoading"
					:cart-totals="cartTotals"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:total-volume="totalVolume"
					:total-weeks="totalWeeks"
					:free-products-bvs-info="freeProductsBvsInfo"
					:cart-coupon="cartCoupon"
					:coupon-errors="couponErrors"
					:coupon-loading="couponLoading"
					:remove-coupon-loading="removeCouponLoading"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:terms-and-policies="termsAndPolicies"
					:show-disclaimer="isEditing(2)"
					:show-sponsor="!isEditing(0) && !isCollapsed(0) && !isAgencySite"
					:apply-free-bottle="applyFreeBottle"
					:free-bottle-message="freeBottleMessage"
					:automatic-discount-percentage="automaticDiscountPercentage"
					:discount-detail="discountDetail"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					:is-customer-registration="isCustomerRegistration"
					:show-backorder-agreement="hasBackorderProducts"
					:pre-checkout-alert="preCheckoutAlert"
					@selected="trackEvent($gtm, 'checkoutConfirmationStart')"
					@couponSubmit="applyCoupon"
					@couponRemove="removeCoupon"
					@couponChange="handleCouponChange"
					@cartValidationError="redirectToStore"
					@invalidRequest="handleInvalidRequest"
					@serverError="redirectToStore"
					@cartReplaced="handleCartReplaced"
					@cantUseSelectedSponsor="redirectToStore"
					@reloadCart="getCartTotals"
					@success="trackEvent($gtm, 'checkoutConfirmationEnd')" />
			</div>
		</div>
		<!-- <custom-modal
			:show.sync="showPromoModal"
			:close-text="translate('close')"
			ref-name="promoModal"
			:title="translate('customer_promo_title')"
			:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'">
			<template #sub-title>
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="my-3 custom-hr">
				<h5
					class="promo-sub-title-small"
					v-html="translate('customer_promo_sub_title_2')" />
				<hr
					:class="{
						'mx-3': ['xs', 'sm'].includes(windowWidth),
						'mx-6': !['xs', 'sm'].includes(windowWidth),
					}"
					class="my-3 custom-hr">
				<h3 class="font-weight-bold my-2">
					{{ translate('customer_promo_sub_title_1') }}
				</h3>
				<h5
					class="promo-sub-title-small"
					v-html="translate('customer_promo_sub_title_3')" />
			</template>
			<div class="text-center px-3">
				<h5 class="text-secondary-dark mb-3">
					{{ translate('customer_promo_body_1') }}
				</h5>
				<span
					class="text-secondary-dark"
					v-html="translate('customer_promo_body_2')" />
			</div>
			<template #footer>
				<div class="text-center text-secondary-dark font-weight-bold mt-4 pt-2 pb-4">
					<div class="mb-2">
						<img
							class="mr-2"
							width="20px"
							:src="require(`@/assets/images/themes/${themeName}/facebook-icon-2x.png`)">
						{{ facebook }}
					</div>
					<div>
						<img
							class="mr-2"
							width="20px"
							:src="require(`@/assets/images/themes/${themeName}/instagram-icon-2x.png`)">
						{{ instagram }}
					</div>
				</div>
			</template>
		</custom-modal> -->
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
// import EventTicket from '@/components/EventTicket';
import DocumentTitle from '@/mixins/DocumentTitle';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import CartData from '@/mixins/CartData';
import WindowSizes from '@/mixins/WindowSizes';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { CLEAR_ON_EXIT_PAYMENT_METHODS } from '@/settings/Purchase';
import { COMPANY_FACEBOOK, COMPANY_INSTAGRAM } from '@/settings/General';
import {
	Cart, Grids, Profile, Purchase, Validations, CustomerPromoModal, /* Events, */
} from '@/translations';
import PossibleDiscounts from '@/components/PossibleDiscounts';
// import CustomModal from '@/components/CustomModal';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import PersonalInformationOverview from './components/PersonalInformation/Overview';
import PersonalInformationEdit from './components/PersonalInformation/Edit';
import ShippingEdit from './components/Shipping/Edit';
import ShippingOverview from './components/Shipping/Overview';
import Steps from './mixins/Steps';
import store from './store';
import PublicStore from '@/mixins/PublicStore';
import { trackEvent } from '@/util/GoogleTagManager';
import Checkout from '@/util/Purchase';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';

export default {
	name: 'CheckoutConfirmation',
	messages: [Cart, Grids, Profile, Purchase, Validations, CustomerPromoModal/* , Events */],
	components: {
		PossibleDiscounts,
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentOverview,
		PaymentEdit,
		PersonalInformationOverview,
		PersonalInformationEdit,
		ShippingOverview,
		ShippingEdit,
		// CustomModal,
		// EventTicket,
	},
	mixins: [CartData, DocumentTitle, Steps, WindowSizes, PublicStore],
	data() {
		return {
			canLeave: false,
			collapseAll: false,
			showPromoModal: false,
			themeName: process.env.VUE_APP_THEME,
			facebook: COMPANY_FACEBOOK,
			instagram: COMPANY_INSTAGRAM,
			predefinedInformation: {
				personalInformation: {},
				shippingInformation: {},
				forceCreateAccount: false,
			},
			validatorSponsor: new Checkout(),
			sponsorInfo: {},
			ONLY_TUUN_COUNTRIES,
			paymentMethodConditions: {},
		};
	},
	computed: {
		hasAutoship() {
			try {
				const { products } = JSON.parse(JSON.stringify(store.state.CheckoutShipping)).shipping.autoship;
				return !!Object.keys(products).length;
			} catch (error) {
				return false;
			}
		},
		isCustomerRegistration() {
			try {
				return JSON.parse(JSON.stringify(store.state.CheckoutPersonalInformation)).create_account;
			} catch (error) {
				return false;
			}
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('CheckoutPayment') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name;
		},
		automaticDiscountPercentage() {
			// FIXME: This computed should return 0 when a promotion is active
			try {
				if (!ONLY_TUUN_COUNTRIES.includes(this.getStoredCountry())) {
					return this.sponsorInfo.auto_apply_customer_coupons ?? 0;
				}
				return 0;
			} catch (error) {
				return 0;
			}
		},
	},
	watch: {
		cartProducts(newVal) {
			if (!newVal.length) {
				this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
				setTimeout(() => {
					this.redirectToStore();
				}, 6000);
			}
		},
		hasAutoship(newVal) {
			this.cartOptions.has_autoship = newVal ? 1 : 0;
		},
		isCustomerRegistration(newVal) {
			this.cartOptions.customer_registration = newVal ? 1 : 0;
		},
		cartNumericTotal(amountToPay) {
			if (typeof this.paymentMethodConditions !== 'undefined') {
				const min = this.paymentMethodConditions.min || null;
				const max = this.paymentMethodConditions.max || null;
				const subtotalMin = min !== null ? amountToPay >= min : true;
				const subtotalMax = max !== null ? amountToPay <= max : true;
				if (!subtotalMin || !subtotalMax) {
					this.clearPaymentMethodData({ clearSelectedPaymentMethod: true });
				}
			}
		},
	},
	mounted() {
		if (!this.showPublicStore) {
			this.redirectToStore().catch(() => {});
			return;
		}
		this.setCartFlow('checkout');
		const replicatedPage = this.$replicated.replicatedPage();
		// When the replicated page is 'checkout', it means that we have put a custom cart id in the cartId variable (handleReplicatedSiteRedirects), so we need to get the custom cart information.
		if (replicatedPage === 'checkout' && localStorage.getItem('custom_cart_uuid')) {
			// Get the user's predefined information using the custom cart id. And fill the inputs with the response.
			this.customCart.getCustomCart().then((response) => {
				this.predefinedInformation.personalInformation = { ...response.attributes.user_information.personal_information };
				this.predefinedInformation.shippingInformation = { ...response.attributes.user_information.shipping_information.shipping_address };
				this.predefinedInformation.forceCreateAccount = response.attributes.force_create_account;
			});
		}
		const sponsor = this.$replicated.replicatedSponsor();
		this.cartOptions.has_autoship = this.hasAutoship ? 1 : 0;
		this.cartOptions.customer_registration = this.isCustomerRegistration ? 1 : 0;
		this.cartOptions.sponsor = sponsor;
		this.getStartingInfo();
		this.validatorSponsor.getSponsorInfo(sponsor)
			.then((response) => {
				this.sponsorInfo = response.response;
			})
			.catch((error) => Promise.reject(error));
	},
	methods: {
		async getStartingInfo() {
			const options = {};
			options.clearSelectedPaymentMethod = true;
			await this.clearPaymentMethodData(options);

			await this.resetIfCountryHasChanged();
			this.goToStep(store.getters.getFirstIncompleteStep());
			this.setCartCookie(this.$cookie.get('cart_id'), { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.currentStep() === step;
		},
		isCollapsed(step) {
			return this.currentStep() < step && !this.stepIsCompleted(step);
		},
		isCompact(step) {
			return !this.isEditing(step) && this.stepIsCompleted(step);
		},
		canOpenEditing(step) {
			return this.stepIsCompleted(step);
		},
		handleInvalidRequest() {
			this.createNewCart();
			// this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			// setTimeout(() => {
			// 	this.resetStepsAndRedirect();
			// }, 6000);
		},
		handleCartReplaced(cartId, reloadCart = true) {
			this.setCartCookie(cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` }, reloadCart);
		},
		resetStepsAndRedirect() {
			store.dispatch('removeInfo').then(() => {
				this.redirectToStore();
			}).catch(() => {});
		},
		redirectToStore() {
			this.canLeave = true;
			this.$router.replace({ name: this.getRedirectName('Store') });
		},
		changePaymentMethodSelected(selected, conditions) {
			if (selected !== 0) this.clickedPayment = selected;
			if (conditions) {
				this.paymentMethodConditions = conditions;
			}
		},
		clearPaymentMethodData(options = {}) {
			const paymentInformation = store.getters.getStepInformation('CheckoutPayment');

			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			const paymentMethodName = paymentInformation?.payment?.payment_method?.name;
			const { clearSelectedPaymentMethod } = options;
			if (CLEAR_ON_EXIT_PAYMENT_METHODS.includes(paymentMethodName)) {
				const data = {
					CheckoutPayment: {
						payment: {
							billing: paymentInformation.payment.billing,
						},
					},
				};
				if (!clearSelectedPaymentMethod) {
					data.CheckoutPayment.payment.payment_method = {
						name: paymentInformation.payment.payment_method.name,
					};
				}
				return store.dispatch('saveInfo', data)
					.then(() => this.goToStep(2))
					.then(() => store.dispatch('saveStepAsIncomplete', 2));
			}

			return null;
		},
		async resetIfCountryHasChanged() {
			const storedCountry = store.getters.getCheckoutCountry();
			if (storedCountry !== '' && storedCountry !== this.country) {
				await store.dispatch('removeInfo');
			}
			store.dispatch('saveCountry', this.country);
		},
		savePersonalInfo() {
			// if (this.isCustomerRegistration) {
			// 	this.showPromoModal = true;
			// }
			this.getCartTotals();
		},
		trackEvent,
		updateItemQty(newQty, product) {
			const shippingInfo = store.getters.getStepInformation('CheckoutShipping');
			// eslint-disable-next-line camelcase
			const pickupAtOffice = shippingInfo?.shipping?.pickup_at_office ?? null;
			this.updateQuantity(newQty, product, false, pickupAtOffice);
		},
		removeItem(product) {
			const shippingInfo = store.getters.getStepInformation('CheckoutShipping');
			// eslint-disable-next-line camelcase
			const pickupAtOffice = shippingInfo?.shipping?.pickup_at_office ?? null;
			this.removeProduct(product, pickupAtOffice);
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('Checkout')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		}
	},
};
</script>
<style scoped>
.custom-hr {
	opacity: 1;
	border-top: 1px solid rgb(255 255 255 / 10%);
}

.promo-sub-title-small {
	line-height: 23px;
	font-size: 0.95rem !important;
	font-weight: initial;
}
</style>
